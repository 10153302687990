import React, { useState } from 'react';

const Videos = ({ data }) => {
    const [isHover, setIsHover] = useState(false);

    // Transformer l'URL en URL d'embed YouTube
    const getYouTubeEmbedUrl = (url) => {
        const videoId = url?.match(/(?:https?:\/\/)?(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-]+)/);
        console.log(url)
        return videoId ? `https://www.youtube.com/embed/${videoId[1]}` : null;
    };

    const embedUrl = getYouTubeEmbedUrl(data?.videos_link?.url);

    return (
        <div className="testimonials__item" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
            {embedUrl ? (
                <iframe
                    src={embedUrl}
                    title={data.videos_title && data.videos_title[0].text}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    width="100%"
                    height="315"
                ></iframe>
            ) : (
                <p>Video non disponible</p>
            )}
            <article>
                {data.videos_title && <h3 className="title is-3 has-text-centered">{data.videos_title[0].text}</h3>}
                {data.videos_content && <p>{data.videos_content[0].text}</p>}
            </article>
            {data.button_content && (
                <a href={data.button_link.url} target="_blank" rel="noopener noreferrer">
                    {data.button_content[0].text}
                </a>
            )}
        </div>
    );
};

export default Videos;